/* body {
  margin: 0;
  font-family: "Open Sans", sans-serif, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

/* SCROLLBARS */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-height: thin;
}

label {
  font-size: 0.857143em;
  font-style: inherit;
  line-height: 1.33333;
  color: rgb(107, 119, 140);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 4px;
  margin-top: 0
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f0f0;
}

*::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 16px;
}

